<template>
  <v-container fluid>
    <Todo></Todo>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-calendar-week</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Wochenübersicht</v-toolbar-title>

      <v-spacer />
      <SearchField v-model="search"></SearchField>
      <template v-slot:extension>
        <v-tabs align-with-title>
          <v-tabs-slider color="error"></v-tabs-slider>
          <v-tab :to="{ name: 'WeekOverviewSchoolclasses' }"> Klassen </v-tab>
          <v-tab :to="{ name: 'WeekOverviewSchoolclassesCalendar' }">
            Kalender
          </v-tab>
          <v-tab disabled>Lehrkräfte </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <router-view :search="search"></router-view>
  </v-container>
</template>
<script>
import SearchField from "@/components/SearchField";

export default {
  name: "WeekOverview",
  components: {
    SearchField,
  },
  data() {
    return {
      search: null,
    };
  },
};
</script>
